import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  isLoading: false,
  status: null,
};

export const submitException = createAsyncThunk(
  'exception/exception',
  async (values, { rejectWithValue }) => {
    try {
      const data = await axiosInstance.post(
        `/api/User/post-Feexceptionlogs?ex=${values}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const exceptionFESlice = createSlice({
  name: 'exception',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(submitException.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload;
      })
      .addCase(submitException.pending, (state) => {
        state.isLoading = true;
        state.status = null;
      })
      .addCase(submitException.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload;
      });
  },
});

export default exceptionFESlice.reducer;
