import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentContext } from '../../../context/AppointmentBook/AppointmentBook';
import { insData } from '../../../data/insuranceData/insuranceData';
import { insuranceCareerList } from '../../../redux/insuranceSlice/insuranceCareerSlice';
import Button from '../../../UI/Button/Button';
// import Category from "../../../UI/Category/Category";

export const AutoCompleteDropdown = ({ data, onSelect }) => {
  const [type, setType] = useState(data);
  const { setInsuranceID } = useContext(AppointmentContext);
  // const onAppointmentList = (item) => {
  //   const tempApp = [...appointmentTypeData];
  //   tempApp?.map((typeItem, typeIdx) => {
  //     if (typeItem.id === item.id) {
  //       setBtnTitle(item.title);
  //       typeItem.isSelect = true;
  //     } else {
  //       typeItem.isSelect = false;
  //     }
  //   });
  //   setType(tempApp);
  //   setIsAppModal(false);
  // };

  return (
    <div className='ne_dropdown_menu'>
      <ul className='ne_dropdown_list'>
        {data?.map((appTypItem, idx) => {
          return (
            <li
              className='ne-scope py-2 p-0'
              key={idx}
              onClick={() => {
                onSelect(appTypItem.id, appTypItem.value);
                setInsuranceID(appTypItem.id);
              }}
            >
              <a className='ne_dropdown_item'>
                <span className='ne-binding'>{appTypItem.value}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Insurance = () => {
  const {
    selectedCareer,
    setSelectedCareer,
    insuranceData,
    setInsuranceData,
    dropStatus,
    setDropStatus,
    setIsNoInsurance,
    setOtherInsurance,
  } = useContext(AppointmentContext);
  const [insuranceCareerData, setInsuranceCareerData] = useState([]);
  const [filteredCareerData, setFilteredCareerData] = useState([]);

  const { insuranceCareer } = useSelector((state) => state.insuranceCareerList);

  useEffect(() => {
    if (insuranceCareer?.length > 0) {
      const tempIncCareer = [
        ...insuranceCareer,
        {
          id: Math.random(),
          carrierName: 'Others',
        },
      ];
      const incCareer = tempIncCareer?.map((item, idx) => {
        return {
          id: item.id,
          value: item.carrierName,
        };
      });
      setInsuranceCareerData(incCareer);
    }
  }, [insuranceCareer]);

  const onInsurance = (idx) => {
    if (idx === 1) {
      setDropStatus({
        ...dropStatus,
        isCareerField: true,
      });
    } else {
      setDropStatus({
        ...dropStatus,
        isCareer: false,
        isCareerField: false,
        isPlanField: false,
      });
      setInsuranceData({
        ...insuranceData,
        career: '',
        careerPlan: '',
      });
      setSelectedCareer({
        ...selectedCareer,
        firstStep: false,
        secondStep: false,
        thirdStep: true,
      });
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(insuranceCareerList());
  }, []);

  const onCareerSelect = (id, value) => {
    setInsuranceData({
      ...insuranceData,
      career: value,
    });
    if (value === 'Others') {
      // dispatch(insurancePlanList(id));
      setDropStatus({
        ...dropStatus,
        isCareer: false,
        isPlanField: true,
      });
    } else {
      setDropStatus({
        ...dropStatus,
        isCareer: false,
        isPlanField: false,
      });

      setSelectedCareer({
        ...selectedCareer,
        firstStep: false,
        secondStep: false,
        thirdStep: true,
      });
    }
  };

  const matchArrays = (val, data) => {
    let tempSuggestion = [];

    if (val?.length > 0) {
      //const regex = new RegExp(`^${val}`, "i");
      // tempSuggestion = data.sort().filter((v) => regex.test(v.value));
      tempSuggestion = data.filter((v) =>
        v.value?.toLowerCase()?.includes(val?.toLowerCase())
      );
    }

    return tempSuggestion;
  };

  // filterCareerItems

  const filterCareerItems = (e) => {
    const val = e.target.value;
    setInsuranceData({
      ...insuranceData,
      career: val,
    });

    const filteredData = matchArrays(val, insuranceCareerData);

    if (filteredData?.length > 0) {
      setFilteredCareerData(filteredData);
      setDropStatus({
        ...dropStatus,
        isCareer: true,
        isCareerPlan: false,
        isPlanField: false,
      });
    } else {
      setDropStatus({
        ...dropStatus,
        isCareer: false,
        isCareerPlan: false,
        isPlanField: false,
      });
      setFilteredCareerData([]);
    }
  };

  const onSelectPlan = (id, value) => {
    setSelectedCareer({
      ...selectedCareer,
      firstStep: false,
      secondStep: false,
      thirdStep: true,
    });
  };

  const filterPlanItems = (e) => {
    const val = e.target.value;
    setOtherInsurance(val);
    setInsuranceData({
      ...insuranceData,
      careerPlan: val,
    });
  };

  return (
    <div>
      <ul>
        {insData?.map((insItem, apIdx) => {
          return (
            <li
              key={apIdx}
              className={`${
                insItem.isSelect ? 'category_list_active' : 'category_list '
              } primary-mg-ver d-flex justify-content-between px-3 py-2 my-2 `}
              onClick={() => (
                onInsurance(apIdx), setIsNoInsurance(apIdx === 0 ? true : false)
              )}
            >
              <span className='list-title '>{insItem.title}</span>

              {insItem.isSelect && (
                <img
                  src="data:image/svg+xml;utf8,<svg width='500px' height='500px' viewBox='0 0 500 500' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>\a <g id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>\a <g id='tick' fill='%23FFFFFF'>\a <path d='M105.976145,225.771999 C93.4878762,213.266719 73.2266015,213.25293 60.7213218,225.741199 C48.216042,238.229468 48.2022522,258.490743 60.6905214,270.996022 L185.869066,396.345076 C198.365712,408.858744 218.643547,408.862928 231.145356,396.354419 L479.74519,147.621355 C492.238619,135.12123 492.233192,114.859951 479.733067,102.366522 C467.232942,89.873093 446.971664,89.8785208 434.478235,102.378645 L208.521221,328.456754 L105.976145,225.771999 Z' id='Shape-Copy-3'></path>\a </g>\a </g>\a </svg>"
                  className='list_active_img'
                />
              )}
            </li>
            // <Category
            //   item={insItem}
            //   key={apIdx}
            //   onClick={() => onInsurance(apIdx)}
            //   idx={insItem.id}
            //   setIsNoInsurance={setIsNoInsurance}
            // />
          );
        })}
      </ul>

      {(dropStatus.isCareerField || dropStatus.isPlanField) && (
        <Row className='m-0'>
          {dropStatus.isCareerField && (
            <Col className='mt-4 px-1 text_input_col' md={12}>
              <TextField
                className='text_input'
                id='insCareer'
                label='Type to search for insurance carrier'
                onChange={(e) => filterCareerItems(e)}
                value={insuranceData.career}
                type='text'
                variant='standard'
                autoComplete='off'
                autoFocus={true}
              />

              {dropStatus.isCareer && (
                <div className='drop_career'>
                  <AutoCompleteDropdown
                    data={filteredCareerData}
                    onSelect={onCareerSelect}
                  />
                </div>
              )}
            </Col>
          )}

          {dropStatus.isPlanField && (
            <Col className='mt-4 px-1 text_input_col ' md={12}>
              <TextField
                className='text_input'
                id='insCareerPlan'
                label='Type to search for insurance carrier'
                onChange={(e) => filterPlanItems(e)}
                value={insuranceData.careerPlan}
                type='text'
                variant='standard'
                autoComplete='off'
                autoFocus={true}
              />
              <Col className='w-100 d-flex justify-content-center'>
                <Button
                  title='Submit'
                  disabled={insuranceData.careerPlan?.length > 3 ? false : true}
                  className='ne_btn mt-3 ne_btn_primary d-flex justify-content-center align-items-center'
                  onClick={onSelectPlan}
                />
              </Col>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

export default Insurance;
