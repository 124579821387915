import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';
import moment from 'moment';

const initialState = {
  clinics: [],
  isLoading: false,
  isError: null,
  status: '',
  /////////////////
  slots: [],
  slotsLoading: false,
  slotsError: null,
  ////////////////////////////
  allClinics: [],
  isLoadingAllClinics: false,
  isErrorAllError: null,
  /////////////////////////////
  getAllClinics: [],
  getClinicsLoading: false,
  getClinicsError: null,
  schedule: [],
  isLoadingSchedule: false,
  statusSchedule: '',
  isErrorSchedule: null,
  ///////////////////////////
  ClinicFormsForClinics: [],
  isLoadingClinicFormsForClinics: false,
  statusClinicFormsForClinics: '',
  isErrorClinicFormsForClinics: null,
  ////////////////////////////////////
  DocCategoryForClinics: [],
  isLoadingDocCategoryForClinics: false,
  statusDocCategoryForClinics: '',
  isErrorDocCategoryForClinics: null,
};

export const fetchClinics = createAsyncThunk(
  'clinics/clinicsList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/clinic/by-user?active=${params}`
      );
      const clinicData = data;
      if (clinicData.status.isSuccess === true) {
        clinicData.data.sort((a, b) => {
          const nameA = a.clinicName.toLowerCase();
          const nameB = b.clinicName.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        const newData = clinicData.data?.map((o) => {
          return {
            ...o,
            inactive: o.active ? 'active' : 'inactive',
            createdOnForUpdate: o.createdOn,
            createdOn: moment(new Date(o.createdOn)).format('MM/DD/YYYY'),
          };
        });

        return newData;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchAllClinics = createAsyncThunk(
  'allClinics/allClinicsList',
  async (startWith, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Clinic/get-allclinics?Active=${startWith}`
      );
      data.sort(
        (a, b) => a.clinicName.toLowerCase() > b.clinicName.toLowerCase()
      );
      const newData = data?.map((o) => {
        return {
          ...o,
          inactive: o.active ? 'active' : 'inactive',
          createdOnForUpdate: o.createdOn,
          createdOn: moment(new Date(o.createdOn)).format('MM/DD/YYYY'),
        };
      });
      return newData;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchAppointmentSlots = createAsyncThunk(
  'appointment/appointmentList',
  async (item, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/clinic/appointmentslots?providerId=${item?.dentalProviderId}&id=${item?.clinicId}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchClinicsByOrg = createAsyncThunk(
  'clinicsByOrg/clinicsByOrgList',
  async (orgId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/clinic/show-clinic?Id=${orgId}`
      );
      const clinicByOrgData = data;
      if (clinicByOrgData.status.isSuccess === true) {
        const newData = clinicByOrgData.data?.map((o) => {
          return {
            ...o,
            inactive: o.active ? 'active' : 'inactive',
            // createdOnForUpdate: o.createdOn,
            // createdOn: moment(new Date(o.createdOn)).format("MM/DD/YYYY"),
          };
        });
        // console.log('data', data)
        return newData;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchSchedule = createAsyncThunk(
  'Schedule/ScheduleList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/clinic/get-schedules?id=${params}`
      );
      // console.log('data', data)
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchClinicFormsForClinics = createAsyncThunk(
  'ClinicFormsForClinics/ClinicFormsListForClinics',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        `/api/clinic/show-forms/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchDocCategoryForClinics = createAsyncThunk(
  'DocCategoryForClinics/DocCategoryForClinicsList',
  async (params, { rejectWithValue }) => {
    // console.log('params', params)
    try {
      const { data } = await axiosInstance.get(
        `/api/clinic/get-doccategory/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const clinicsSlice = createSlice({
  name: 'clinicsList',
  initialState,
  extraReducers: {
    [fetchClinics.fulfilled]: (state, { payload }) => {
      state.clinics = payload;
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchClinics.pending]: (state, { payload }) => {
      state.clinics = [];
      state.isLoading = true;
      state.status = '';
      state.isError = null;
    },
    [fetchClinics.rejected]: (state, { payload }) => {
      state.clinics = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = 'bad req';
    },
    [fetchAppointmentSlots.fulfilled]: (state, { payload }) => {
      state.slots = payload;
      state.slotsLoading = false;
      state.slotsError = false;
    },
    [fetchAppointmentSlots.pending]: (state, { payload }) => {
      state.slots = [];
      state.slotsLoading = true;
      state.slotsError = false;
    },
    [fetchAppointmentSlots.rejected]: (state, { payload }) => {
      state.slots = [];
      state.slotsLoading = false;
      state.slotsError = true;
    },
    [fetchClinicsByOrg.fulfilled]: (state, { payload }) => {
      state.allClinics = payload;
      state.isLoadingAllClinics = false;
      state.isErrorAllError = false;
    },
    [fetchClinicsByOrg.pending]: (state, { payload }) => {
      state.allClinics = [];
      state.isLoadingAllClinics = true;
      state.isErrorAllError = false;
    },
    [fetchClinicsByOrg.rejected]: (state, { payload }) => {
      state.allClinics = [];
      state.isLoadingAllClinics = false;
      state.isErrorAllError = true;
    },
    [fetchAllClinics.fulfilled]: (state, { payload }) => {
      state.getAllClinics = payload;
      state.getClinicsLoading = false;
      state.getClinicsError = false;
    },
    [fetchAllClinics.pending]: (state, { payload }) => {
      state.getAllClinics = [];
      state.getClinicsLoading = true;
      state.getClinicsError = false;
    },
    [fetchAllClinics.rejected]: (state, { payload }) => {
      state.getAllClinics = [];
      state.getClinicsLoading = false;
      state.getClinicsError = true;
    },
    [fetchSchedule.fulfilled]: (state, { payload }) => {
      if (payload?.status?.isSuccess === false) {
        state.schedule = [];
      } else {
        state.schedule = payload;
      }
      state.isLoadingSchedule = false;
      state.statusSchedule = 'Success';
      state.isErrorSchedule = null;
    },
    [fetchSchedule.pending]: (state, { payload }) => {
      state.schedule = [];
      state.isLoadingSchedule = true;
      state.statusSchedule = '';
      state.isErrorSchedule = null;
    },
    [fetchSchedule.rejected]: (state, { payload }) => {
      state.schedule = [];
      state.isLoadingSchedule = false;
      state.isErrorSchedule = payload;
      state.statusSchedule = 'bad req';
    },
    [fetchClinicFormsForClinics.fulfilled]: (state, { payload }) => {
      state.ClinicFormsForClinics = payload;
      state.isLoadingClinicFormsForClinics = false;
      state.statusClinicFormsForClinics = 'Success';
      state.isErrorClinicFormsForClinics = null;
    },
    [fetchClinicFormsForClinics.pending]: (state, { payload }) => {
      state.ClinicFormsForClinics = [];
      state.isLoadingClinicFormsForClinics = true;
      state.statusClinicFormsForClinics = '';
      state.isErrorClinicFormsForClinics = null;
    },
    [fetchClinicFormsForClinics.rejected]: (state, { payload }) => {
      state.ClinicFormsForClinics = [];
      state.isLoadingClinicFormsForClinics = false;
      state.isErrorClinicFormsForClinics = payload;
      state.statusClinicFormsForClinics = 'bad req';
    },
    [fetchDocCategoryForClinics.fulfilled]: (state, { payload }) => {
      state.DocCategoryForClinics = payload;
      state.isLoadingDocCategoryForClinics = false;
      state.statusDocCategoryForClinics = 'Success';
      state.isErrorDocCategoryForClinics = null;
    },
    [fetchDocCategoryForClinics.pending]: (state, { payload }) => {
      state.DocCategoryForClinics = [];
      state.isLoadingDocCategoryForClinics = true;
      state.statusDocCategoryForClinics = '';
      state.isErrorDocCategoryForClinics = null;
    },
    [fetchDocCategoryForClinics.rejected]: (state, { payload }) => {
      state.DocCategoryForClinics = [];
      state.isLoadingDocCategoryForClinics = false;
      state.isErrorDocCategoryForClinics = payload;
      state.statusDocCategoryForClinics = 'bad req';
    },
  },
});

export default clinicsSlice.reducer;
