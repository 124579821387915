import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import withClickOutside from '../../WithClickOutside/WidthClickOutside';
import { AppointmentContext } from '../../../context/AppointmentBook/AppointmentBook';
import { Ellipse } from '../../../UI/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  getTimeSlotList,
  selTimeList,
} from '../../../redux/timeSlotSlice/timeSlot';

export const mS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const AppointmentDate = forwardRef(({ open, setOpen, props }, ref) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [filteredTimeList, setFilteredTimeList] = useState([]);
  const { timeList, timeListLoading } = useSelector((state) => state.timeSlot);
  const [highlightedDates, setHighlightedDates] = useState([]);
  // console.log('filteredTimeList', highlightedDates)
  const {
    // date,
    // activeDays,
    // isActiveMonth,
    bookedTime,
    bookedAddress,
    setBookedTime,
    selectedCareer,
    setSelectedCareer,
    // setIsActiveMonth,
    // setCalendar,
    selectedAdd,
    setSlotId,
  } = useContext(AppointmentContext);

  const handleDateChange = (e) => {
    setSelectedDate(e);
  };

  useEffect(() => {
    const initialFilteredTimeList = timeList.filter((item) => {
      const itemDate = moment(item.itemDate);
      const formattedItemDate = itemDate.format('MM-DD-YYYY');

      return moment(formattedItemDate).isSame(selectedDate, 'day');
    });
    setFilteredTimeList(initialFilteredTimeList);
  }, [timeList, selectedDate]);

  useEffect(() => {
    const uniqueDates = new Set();

    // Filter the array based on unique formatted itemDate values
    const filteredArray = timeList.filter((item) => {
      const formattedItemDate = moment(item.itemDate).format('DD-MM-YYYY');
      if (!uniqueDates.has(formattedItemDate)) {
        uniqueDates.add(formattedItemDate);
        return true;
      }
      return false;
    });

    // Extract only the itemDate values from the filtered array
    const itemDates = filteredArray?.map((item) =>
      moment(item.itemDate).format('DD-MM-YYYY')
    );

    // Update the state with the filtered array
    setHighlightedDates(itemDates);
  }, [timeList]);

  const onTimeSlot = (subItem) => {
    // console.log('subItem', subItem)
    const tempTimeList = [...filteredTimeList];
    const felTimeList = tempTimeList?.map((tItem, tdx) => {
      if (tItem.id === subItem.id) {
        setSlotId(tItem?.slotId);
        return {
          ...tItem,
          selected: true,
        };
      } else {
        return {
          ...tItem,
          selected: false,
        };
      }
    });
    // console.log('felTimeList', felTimeList)
    dispatch(selTimeList(felTimeList));

    setSelectedCareer({
      ...selectedCareer,
      thirdStep: false,
      fourthStep: true,
    });

    setBookedTime({
      ...bookedTime,
      month: moment(subItem.itemDate).format('MMMM'),
      weekName: moment(subItem.itemDate).format('dddd'),
      day: subItem.itemDate.split('-')[2],
      year: subItem.itemDate.split('-')[0],
      time: subItem.itemTime,
      dateJson: subItem.time,
      // operatoryId: subItem.operatoryId,
    });
  };

  useEffect(() => {
    dispatch(
      getTimeSlotList({
        id: bookedAddress?.id,
        providerId: selectedAdd?.providerId,
        operatoryId: selectedAdd?.operatoryID,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('highlightedDates', highlightedDates)
  return (
    <Row className='w-100 m-0'>
      <Col lg={4} md={6} sm={12} className='d-flex justify-content-between'>
        <fieldset className='ne_dropdown mr-0'>
          <h6 className=' text-center text_color'>Availability</h6>
          <Row className='ne_dropdown_calendar'>
            <Calendar
              onChange={(e) => handleDateChange(e)}
              value={selectedDate}
              tileClassName={({ date }) =>
                highlightedDates.includes(moment(date).format('DD-MM-YYYY'))
                  ? 'highlight'
                  : null
              }
              // tileDisabled={({ date }) => date.getDay() === 0}
            />
          </Row>
        </fieldset>
      </Col>
      <Col lg={8} md={6} sm={12}>
        <Row className='px-2'>
          <h6 className='text-center text_color'>
            {filteredTimeList?.length > 0
              ? `Available Slots for ${moment(selectedDate).format(
                  'MM-DD-YYYY'
                )}`
              : `No Slots Available for ${moment(selectedDate).format(
                  'MM-DD-YYYY'
                )}`}
          </h6>
          <Row className='px-2'>
            {timeListLoading ? (
              <Ellipse />
            ) : (
              filteredTimeList?.map((subItem, id) => {
                return (
                  <div
                    key={id}
                    className='ne_time-slot'
                    style={{ width: '20%' }}
                  >
                    <button
                      key={id}
                      className={`ne_time-slot_button `}
                      onClick={() => onTimeSlot(subItem)}
                    >
                      {subItem.itemTime}
                    </button>
                  </div>
                );
              })
            )}
          </Row>
        </Row>
      </Col>
    </Row>
  );
});

export default withClickOutside(AppointmentDate);
