import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ThankYou from "../components/appt/ThankYou/ThankYou";
// import Login from "../components/Auth/Login";
import AppointmentBook from '../pages/AppointmentBook/AppointmentBook';
// import Clinics from "../pages/Dashboard/Clinics";
// import MapLocation from "../pages/MapLocation/MapLocation";
// import Organizations from "../pages/Dashboard/Organizations";
// import Dashboard from "../pages/Dashboard";
// import NotFound from "../redux";
import { ToastContainer } from 'react-toastify';
// import Register from "../components/Auth/Register";
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import { Loader } from '../UI/Loader/Loader';
const PrivacyPolicy = React.lazy(() =>
  import('../components/Footer/PrivacyPolicy')
);
const TermsOfUse = React.lazy(() => import('../components/Footer/TermsOfUse'));
// const AppointmentBook = React.lazy(() => import("../pages/AppointmentBook/AppointmentBook"))
const MapLocation = React.lazy(() =>
  import('../pages/MapLocation/MapLocation')
);
const NotFound = React.lazy(() => import('../redux'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Login = React.lazy(() => import('../components/Auth/Login'));
const Logout = React.lazy(() => import('../components/Auth/Logout'));
function Navigation() {
  const { isLogin } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ToastContainer
        position='bottom-right'
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route
            exact
            path='/'
            element={
              <Suspense
                fallback={
                  <div>
                    <Loader />
                  </div>
                }
              >
                <MapLocation />
              </Suspense>
            }
          />
          <Route
            path='/:slug'
            element={
              <Suspense
                fallback={
                  <div>
                    {' '}
                    <Loader />
                  </div>
                }
              >
                <AppointmentBook />
              </Suspense>
            }
          />
          <Route
            path='Login'
            element={
              <Suspense
                fallback={
                  <div>
                    {' '}
                    <Loader />
                  </div>
                }
              >
                <Login />
              </Suspense>
            }
          />
          <Route
            path='Logout'
            element={
              <Suspense
                fallback={
                  <div>
                    {' '}
                    <Loader />
                  </div>
                }
              >
                <Logout />
              </Suspense>
            }
          />
          {/* <Route path="/register" element={<Register />} /> */}
          {/* <Route
            path='logout'
            element={
              <Suspense
                fallback={
                  <div>
                    {' '}
                    <Loader />
                  </div>
                }
              >
                <Logout />
              </Suspense>
            }
          /> */}
          <Route
            exact
            path='/dashboard/*'
            element={
              <ProtectedRoute isLogin={isLogin}>
                <Suspense
                  fallback={
                    <div>
                      {' '}
                      <Loader />
                    </div>
                  }
                >
                  <Dashboard />
                </Suspense>
              </ProtectedRoute>
            }
          >
            <Route path='/dashboard/*' element={<NotFound />} />
            {/* <Route path="/*" element={<Login />} /> */}
          </Route>
          <Route
            path='privacy-policy'
            element={
              <Suspense fallback={<Loader />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path='terms-of-use'
            element={
              <Suspense fallback={<Loader />}>
                <TermsOfUse />
              </Suspense>
            }
          />
          <Route path='404' element={<NotFound />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
        {/* </Suspense> */}
      </Router>
    </>
  );
}

export default Navigation;
