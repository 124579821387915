import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  organizations: [],
  isLoading: false,
  isError: null,
  status: '',
};

export const fetchOrganization = createAsyncThunk(
  'organizations/organizationsList',
  async (id = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Organization/get-allOrganization`
      );
      const orgData = data;
      if (orgData.status.isSuccess === true) {
        const newData = orgData.data?.map((o) => {
          return {
            ...o,
            inactive: o.active ? 'active' : 'inactive',
            createdOnForUpdate: o.createdOn,
            createdOn: moment(new Date(o.createdOn)).format('MM/DD/YYYY'),
            // createdOn: new Date(o.createdOn).toDateString(),
          };
        });
        return newData;
      } else {
        return [];
      }
    } catch (error) {
      return rejectWithValue('data Not Found');
    }
  }
);

const organizationsSlice = createSlice({
  name: 'organizationsList',
  initialState,
  extraReducers: {
    [fetchOrganization.fulfilled]: (state, { payload }) => {
      state.organizations = payload;
      state.isLoading = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchOrganization.pending]: (state, { payload }) => {
      state.organizations = [];
      state.isLoading = true;
      state.status = '';
      state.isError = null;
    },
    [fetchOrganization.rejected]: (state, { payload }) => {
      state.organizations = [];
      state.isLoading = false;
      state.isError = payload;
      state.status = 'bad req';
    },
  },
});

export default organizationsSlice.reducer;
