import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  isLoading: false,
  status: null,

  //////////////

  closeDayData: [],
  isLoadingCloseDayData: false,
  isError: null,
  closeDayDataStatus: '',
};

// https://api.dentalbookingonline.com/api/Appointment/post-apptschedule?clinicid=66&startDate={date}&IsCloseday=true

export const submitBlockDay = createAsyncThunk(
  'blockDay/blockDayList',
  async (values, { rejectWithValue }) => {
    // console.log('values', values);
    try {
      const data = await axiosInstance.post(
        `/api/Appointment/post-apptschedule?clinicid=${values.clinicid}&startDate=${values.startDate}&IsCloseday=${values.isClosed}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// https://api.dentalbookingonline.com/api/Appointment/get-closedaystatus?clinicid=66&startDate={date}

export const getCloseDayData = createAsyncThunk(
  'getCloseDayData/getCloseDayDataList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Appointment/get-closedaystatus?clinicid=${params.clinicid}&startDate=${params.startDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : 'Data Not Found'
      );
    }
  }
);

const blockDaySlice = createSlice({
  name: 'blockDay',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(submitBlockDay.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload;
      })
      .addCase(submitBlockDay.pending, (state) => {
        state.isLoading = true;
        state.status = null;
      })
      .addCase(submitBlockDay.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload;
      });
    builder
      .addCase(getCloseDayData.fulfilled, (state, { payload }) => {
        if (payload.status?.isSuccess) {
          state.closeDayData = payload.data;
        } else {
          state.closeDayData = [];
        }
        state.isLoadingCloseDayData = false;
        state.closeDayDataStatus = 'Success';
        state.isError = null;
      })
      .addCase(getCloseDayData.pending, (state) => {
        state.isLoadingCloseDayData = true;
        state.closeDayDataStatus = '';
        state.isError = null;
        state.closeDayData = [];
      })

      .addCase(getCloseDayData.rejected, (state, { payload }) => {
        state.isLoadingCloseDayData = false;
        state.isError = payload;
        state.closeDayDataStatus = 'Rejected';
        state.closeDayData = [];
      });
  },
});

export default blockDaySlice.reducer;
