import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  treatmentCoOrdinatorData: [],
  isLoadingtreatmentCoOrdinatorData: false,
  isError: null,
  status: '',

  patientTreatmentCoordinators: [],
  isLoadingPatientTreatmentCoordinators: false,

  submitTreatmentCoordinators: null,
  submitTreatmentCoordinatorsLoading: false,
};

export const fetchTreatmentCoOrdinator = createAsyncThunk(
  'treatmentcoOrdinator/treatmentcoOrdinatortList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/patient/get-patplansbydate/${params.id}/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const getPatientTreatmentCoordinators = createAsyncThunk(
  'treatmentcoOrdinator/PatientTreatmentCoordinators',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `api/patient/get-treatmentordinators/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const submitTreatmentCoordinators = createAsyncThunk(
  'treatmentcoOrdinator/upserttreatmentordinators',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `api/patient/upserttreatmentordinators`,
        params
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

// export const submitUpsertemp = createAsyncThunk(
//   'treatmentcoOrdinator/upsertemp',
//   async (params, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.post(
//         `api/Employee/upsertemp`,
//         params
//       );
//       return data;
//     } catch (error) {
//       return rejectWithValue('Data Not Found');
//     }
//   }
// );

const TreatMentCoOrdinatorSlice = createSlice({
  name: 'treatmentcoOrdinator',
  initialState,
  extraReducers: {
    [fetchTreatmentCoOrdinator.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.treatmentCoOrdinatorData = payload.data;
      } else {
        state.treatmentCoOrdinatorData = [];
      }
      state.isLoadingtreatmentCoOrdinatorData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchTreatmentCoOrdinator.pending]: (state, { payload }) => {
      state.isLoadingtreatmentCoOrdinatorData = true;
      state.status = '';
      state.isError = null;
      state.treatmentCoOrdinatorData = [];
    },
    [fetchTreatmentCoOrdinator.rejected]: (state, { payload }) => {
      state.isLoadingtreatmentCoOrdinatorData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.treatmentCoOrdinatorData = [];
    },

    [getPatientTreatmentCoordinators.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.patientTreatmentCoordinators = payload.data;
      } else {
        state.patientTreatmentCoordinators = [];
      }
      state.isLoadingPatientTreatmentCoordinators = false;
    },
    [getPatientTreatmentCoordinators.pending]: (state, { payload }) => {
      state.isLoadingPatientTreatmentCoordinators = true;
      state.patientTreatmentCoordinators = [];
    },
    [getPatientTreatmentCoordinators.rejected]: (state, { payload }) => {
      state.isLoadingPatientTreatmentCoordinators = false;
      state.patientTreatmentCoordinators = [];
    },

    [submitTreatmentCoordinators.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.submitTreatmentCoordinators = payload.data;
      } else {
        state.submitTreatmentCoordinators = [];
      }
      state.submitTreatmentCoordinatorsLoading = false;
    },
    [submitTreatmentCoordinators.pending]: (state, { payload }) => {
      state.submitTreatmentCoordinatorsLoading = true;
      state.submitTreatmentCoordinators = [];
    },
    [submitTreatmentCoordinators.rejected]: (state, { payload }) => {
      state.submitTreatmentCoordinatorsLoading = false;
      state.submitTreatmentCoordinators = [];
    },
  },
});

export default TreatMentCoOrdinatorSlice.reducer;
