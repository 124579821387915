import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  getEmployee: [],
  isLoadingGetEmployee: false,
  isErrorGetEmployee: null,
  statusGetEmployee: '',
  ////////////////
  getEmployeeRole: [],
  isLoadingGetEmployeeRole: false,
  isErrorGetEmployeeRole: null,
  statusGetEmployeeRole: '',
  ///////////////
  changeEmployeeRole: [],
  isLoadingchangeEmployeeRole: false,
  isErrorhangeEmployeeRole: null,
  statusChangeEmployeeRole: '',
};

export const fetchGetEmployee = createAsyncThunk(
  'getEmployee/getEmployeeList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/Employee/get-empinfo/${params}`
      );
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchGetEmployeeRole = createAsyncThunk(
  'getEmployeeRole/getEmployeeRoleList',
  async (params, { rejectWithValue }) => {
    // console.log('params222----->', params);
    try {
      const { data } = await axiosInstance.get(`/api/Employee/get-emproles`);
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);
// https://api.dentalbookingonline.com/api/Employee/upsertemp
export const fetchchangeEmployeeRole = createAsyncThunk(
  'changeEmployeeRole',
  async (values, { rejectWithValue }) => {
    try {
      const data = axiosInstance.post(`/api/Employee/upsertemp`, values);
      // console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getEmployeeSlice = createSlice({
  name: 'getEmployeeSliceList',
  initialState,
  extraReducers: {
    [fetchGetEmployee.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.getEmployee = payload.data;
      } else {
        state.getEmployee = [];
      }
      // state.getEmployee = payload;
      state.isLoadingGetEmployee = false;
      state.statusGetEmployee = 'Success';
      state.isErrorGetEmployee = null;
    },
    [fetchGetEmployee.pending]: (state, { payload }) => {
      state.getEmployee = [];
      state.isLoadingGetEmployee = true;
      state.statusGetEmployee = '';
      state.isErrorGetEmployee = null;
    },
    [fetchGetEmployee.rejected]: (state, { payload }) => {
      state.getEmployee = [];
      state.isLoadingGetEmployee = false;
      state.isErrorGetEmployee = payload;
      state.statusGetEmployee = 'bad req';
    },
    [fetchGetEmployeeRole.fulfilled]: (state, { payload }) => {
      // console.log('payload222-------->', payload);
      if (payload.status.isSuccess === true) {
        state.getEmployeeRole = payload.data;
      } else {
        state.getEmployeeRole = [];
      }
      // state.practiceIQTxPatientOverview = payload;
      state.isLoadingGetEmployeeRole = false;
      state.statusGetEmployeeRole = 'Success';
      state.isErrorGetEmployeeRole = null;
    },
    [fetchGetEmployeeRole.pending]: (state, { payload }) => {
      state.getEmployeeRole = [];
      state.isLoadingGetEmployeeRole = true;
      state.statusGetEmployeeRole = '';
      state.isErrorGetEmployeeRole = null;
    },
    [fetchGetEmployeeRole.rejected]: (state, { payload }) => {
      state.getEmployeeRole = [];
      state.isLoadingGetEmployeeRole = false;
      state.isErrorGetEmployeeRole = payload;
      state.statusGetEmployeeRole = 'bad req';
    },
    ////////////
    [fetchchangeEmployeeRole.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.changeEmployeeRole = payload.data;
      } else {
        state.changeEmployeeRole = [];
      }
      // state.practiceIQTxPatientOverview = payload;
      state.isLoadingchangeEmployeeRole = false;
      state.statusChangeEmployeeRole = 'Success';
      state.isErrorhangeEmployeeRole = null;
    },
    [fetchchangeEmployeeRole.pending]: (state, { payload }) => {
      state.changeEmployeeRole = [];
      state.isLoadingchangeEmployeeRole = true;
      state.statusChangeEmployeeRole = '';
      state.isErrorhangeEmployeeRole = null;
    },
    [fetchchangeEmployeeRole.rejected]: (state, { payload }) => {
      state.changeEmployeeRole = [];
      state.isLoadingchangeEmployeeRole = false;
      state.isErrorhangeEmployeeRole = payload;
      state.statusChangeEmployeeRole = 'bad req';
    },
  },
});

export default getEmployeeSlice.reducer;
