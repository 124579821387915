import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  collectionPaymentReport: null,
  collectionPaymentReportLoading: false,
};

// https://api.dentalbookingonline.com/api/Patient/upserttreatmentpayments

export const fetchCollectionReportPayment = createAsyncThunk(
  'collectionPayment/upserttreatmentpayments',
  async (params, { rejectWithValue }) => {
    // console.log('params', params);
    try {
      const { data } = await axiosInstance.post(
        `api/patient/upserttreatmentpayments
`,
        params
      );
      // console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const CollectionPaymentReportSlice = createSlice({
  name: 'collectionPayment',
  initialState,
  extraReducers: {
    [fetchCollectionReportPayment.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.collectionPaymentReport = payload.data;
      } else {
        state.collectionPaymentReport = [];
      }
      state.collectionPaymentReportLoading = false;
    },
    [fetchCollectionReportPayment.pending]: (state, { payload }) => {
      state.collectionPaymentReportLoading = true;
      state.collectionPaymentReport = [];
    },
    [fetchCollectionReportPayment.rejected]: (state, { payload }) => {
      state.collectionPaymentReportLoading = false;
      state.collectionPaymentReport = [];
    },
  },
});

export default CollectionPaymentReportSlice.reducer;
