import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  doctorDashboardData: [],
  statusDocotrDashboard: '',
  isErrorDocotrDashboard: null,
  isLoadingDocotrDashboard: false,
};

export const fetchDoctorDashboard = createAsyncThunk(
  'doctorDashboard/doctorDashboardList',
  async (params, { rejectWithValue }) => {
    // console.log('params-------->', params);
    // https://api.dentalbookingonline.com/api/Practice/Get-ProvEnterPriseReports?startdate=2024-11-01&enddate=2024-12-20
    try {
      const { data } = await axiosInstance.get(
        `/api/Practice/Get-ProvEnterPriseReports?startdate=${params.startDate}&enddate=${params.endDate}`
      );
      // console.log('data----->', data);
      return data;
    } catch (error) {
      return rejectWithValue('data Not Found');
    }
  }
);

const doctorDashboardtSlice = createSlice({
  name: 'doctorDashboardList',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorDashboard.fulfilled, (state, { payload }) => {
        // console.log('payload-------->', payload);
        if (payload.status.isSuccess === true) {
          state.doctorDashboardData = payload.data;
        } else {
          state.doctorDashboardData = [];
        }
        state.isLoadingDocotrDashboard = false;
        state.statusDocotrDashboard = 'Success';
        state.isErrorDocotrDashboard = false;
      })
      .addCase(fetchDoctorDashboard.pending, (state) => {
        state.doctorDashboardData = [];
        state.isLoadingDocotrDashboard = true;
        state.statusDocotrDashboard = '';
        state.isErrorDocotrDashboard = false;
      })
      .addCase(fetchDoctorDashboard.rejected, (state) => {
        state.doctorDashboardData = [];
        state.isLoadingDocotrDashboard = false;
        state.isErrorDocotrDashboard = true;
        state.statusDocotrDashboard = 'Rejected';
      });
  },
});

export default doctorDashboardtSlice.reducer;
