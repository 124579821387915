import { useEffect, useState } from 'react';
import './App.scss';
import 'antd/dist/antd.min.css';
import AppointmentContextProvider from './context/AppointmentBook/AppointmentBook';
import Navigation from './route/route';
import { defaultLogOut, getUser } from './services';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
import ErrorFound from './pages/notfound/ErrorFound';

// const ErrorFound = ({ error }) => {
//   const resetErrorBoundary = () => {
//     defaultLogOut();
//     window.location.reload();
//   };
//   return (
//     <div
//       role='alert mt-5'
//       style={{
//         height: '100vh',
//         width: '100vw',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <div>
//         <h2 className='text-center'>Something went wrong</h2>
//         {/* <pre className='text-center'>{error.message}</pre> */}
//         <Button
//           style={{ margin: '0 auto', display: 'flex', flexDirection: 'column' }}
//           onClick={resetErrorBoundary}
//         >
//           Please Try again
//         </Button>
//       </div>
//     </div>
//   );
// };

function App() {
  const handleError = (error, errorInfo) => {};
  const token = localStorage.getItem('_t');

  useEffect(() => {
    const checkExpTimeAndLogOut = () => {
      const expTime = localStorage.getItem('expTime');

      // If expTime is not null or undefined
      if (expTime) {
        // Convert expTime to a Date object
        const expTimeDate = new Date(expTime);

        // Get the current time as a Date object
        const currentTime = new Date();

        // Check if the current time is equal to or past the expiration time
        if (currentTime >= expTimeDate) {
          defaultLogOut();
        }
      }
    };

    checkExpTimeAndLogOut();

    const intervalId = setInterval(checkExpTimeAndLogOut, 1000); // Check every 1 second

    return () => clearInterval(intervalId);
  }, [token]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFound} onError={handleError}>
      <AppointmentContextProvider>
        {/* <AppointmentBook /> */}
        <Navigation />
      </AppointmentContextProvider>
      {/* <BuggyComponent /> */}
    </ErrorBoundary>
  );
}

export default App;
