import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../helper/axiosInstance/axiosInstance';

const initialState = {
  bookingReportData: [],
  isLoadingbookingReportData: false,
  isError: null,
  status: '',

  ///////////
  totalBookingReportData: [],
  isLoadingtotalBookingReportData: false,
  isErrortotalBookingReportData: null,
  totalBookingReportDatastatus: '',
};

export const fetchBookingReport = createAsyncThunk(
  'bookingReport/bookingeportList',
  async (params, { rejectWithValue }) => {
    // console.log('params-------- :>> ', params);
    try {
      const { data } = await axiosInstance.get(
        //https://api.dentalbookingonline.com/api/Dashboard/bookingreports/0/2024-09-02/2024-09-27
        `api/Dashboard/bookingreports/${params.id}/${params.userId}/${params.startDate}/${params.endDate}/${params.groupByDate}/${params.groupByClinic}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

export const fetchTotalBookiReportData = createAsyncThunk(
  'totalBookingReportData/totalBookingReportDataList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        // baseurl/api/Dashboard/totalbookingreports/{start}/{end
        `api/Dashboard/totalbookingreports/${params.startDate}/${params.endDate}`
      );

      return data;
    } catch (error) {
      return rejectWithValue('Data Not Found');
    }
  }
);

const bookingReportSlice = createSlice({
  name: 'bookingeportList',
  initialState,
  extraReducers: {
    [fetchBookingReport.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.bookingReportData = payload.data;
      } else {
        state.bookingReportData = [];
      }
      state.isLoadingbookingReportData = false;
      state.status = 'Success';
      state.isError = null;
    },
    [fetchBookingReport.pending]: (state, { payload }) => {
      state.isLoadingbookingReportData = true;
      state.status = '';
      state.isError = null;
      state.bookingReportData = [];
    },
    [fetchBookingReport.rejected]: (state, { payload }) => {
      state.isLoadingbookingReportData = false;
      state.isError = payload;
      state.status = 'Rejected';
      state.bookingReportData = [];
    },
    [fetchTotalBookiReportData.fulfilled]: (state, { payload }) => {
      if (payload.status.isSuccess === true) {
        state.totalBookingReportData = payload.data;
      } else {
        state.totalBookingReportData = [];
      }
      state.isLoadingtotalBookingReportData = false;
      state.totalBookingReportDatastatus = 'Success';
      state.isErrortotalBookingReportData = null;
    },
    [fetchTotalBookiReportData.pending]: (state, { payload }) => {
      state.isLoadingtotalBookingReportData = true;
      state.totalBookingReportDatastatus = '';
      state.isErrortotalBookingReportData = null;
      state.totalBookingReportData = [];
    },
    [fetchTotalBookiReportData.rejected]: (state, { payload }) => {
      state.isLoadingtotalBookingReportData = false;
      state.isErrortotalBookingReportData = payload;
      state.totalBookingReportDatastatus = 'Rejected';
      state.totalBookingReportData = [];
    },
  },
});

export default bookingReportSlice.reducer;
